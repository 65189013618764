













































































































































import { Component, Vue } from "vue-property-decorator";
import { mdiChevronDown, mdiPhone, mdiMail } from "@mdi/js";

@Component({
    name: "Home"
})
export default class Home extends Vue {
    public icons = {
        mdiChevronDown,
        mdiPhone,
        mdiMail
    }

    private readonly team = [
        {
            name: "Dr. Soonhee Roh",
            about: "Professor in the Department of Social Work at the University of South Dakota. Dr. Roh serves as the principal investigator for this project. She has 10 years of experience in cancer screening and prevention research, and is now expanding that research to include using mobile applications in the American Indian community. This project is an exciting opportunity to bring technology to American Indian women in great need of early screening and detection of breast cancer."
        },
        {
            name: "Dr. Yeon-Shim Lee",
            about: "Professor in the School of Social Work at San Francisco State University. Dr. Lee serves as co-investigator for this project. She has 15 years of experience studying health disparities, minority health and service utilization, aging, and health behaviors (including substance use and family violence) among racial/ethnic minority populations. As co-investigator, Dr. Lee uses her expertise in designing culturally sensitive interventions as well as qualitative and quantitative methods. She brings additional expertise in the study of contextual issues such as health beliefs and norms in understanding cancer screening behaviors."
        },
        {
            name: "Dr. Denyelle B. Kenyon",
            about: "Associate Dean for Diversity and Inclusion at the Sanford School of Medicine and Program Director for the Master of Public Health in the School of Health Sciences at the University of South Dakota. She has over 13 years of experience working with American Indian communities. Dr. Kenyon serves as a primary mentor on this project. For this project she provides mentorship in: breast cancer, health behavior change theory, intervention development and design, and evaluation to better inform breast cancer screening intervention trials."
        },
        {
            name: "Dr. Amy J. Elliott",
            about: "Principal investigator on several National Institutes of Health (NIH) research projects: 1) the Environmental Influences on Child Health Outcomes (ECHO) Study, an initiative to reduce infant mortality in American Indian communities and 2) the Safe Passage study examining environmental influences on child health outcomes. Dr. Elliott serves as the secondary mentor for this project. For this project she provides mentorship in: American Indian health disparities, tribal institutional review boards (IRB), ethical and responsible conduct of research, and community engagement."
        },
        {
            name: "Dr. Daniel G. Petereit, MD",
            about: "Medical Director of Research at Rapid City Regional Hospital and a professor in the Sanford School of Medicine at the University of South Dakota. Dr. Petereit is the principal investigator for National Cancer Institute grants—approximately 17 million dollars—supporting the Native American cancer research program Walking Forward. He serves as the clinical mentor for this project. For this project he provides mentorship in breast cancer prevention and control among American Indian women."
        },
        {
            name: "Ms. Serene Thin-Elk",
            about: "Co-Founder and Director of Generation Red Road, Inc. She is the project coordinator for this study. She manages a Native American non-profit focused on bringing mental health and addiction services to organizations and communities. In her work she does outreach, gives presentations, coordinates youth camps, and facilitates professional workshops."
        },
        {
            name: "Ms. Sasheen Thin Elk",
            about: "Realty Director at Yankton Sioux Tribe in South Dakota. She is a research assistant for this project. She has worked on several research projects in the American Indian community and also appointed member of the Charles Mix Farm Service Agency (FSA) County Committee (COC) for the State of South Dakota."
        },
        {
            name: "Dr. Hee Yun Lee",
            about: "Associate Dean for Research, and Endowed Academic Chair on Social Work and Health at the University of Alabama School of Social Work. She is a behavioral health scientist and her major research areas are health disparities and cancer prevention, treatment, and survivorship. She has more than a decade of experience in clinical trials of cancer screening and survivorship interventions using mobile health (mHealth) technology. She uses mobile applications (app), wearable devices/sensors, and social network sites as intervention mediums to change health behaviors, predominantly among underserved populations. To date, she has developed five different types of app and web-based applications to promote positive health behavior changes and three app-based intervention studies are currently in progress. She serves as a consultant for the current project."
        },
        {
            name: "Dr. Ahyoung Lee",
            about: "Assistant Professor in the Computer Science Department at Kennesaw State University. Web app developer for the project. She led the team in the creation of the wMammogram web app application. Dr. Lee conducts technical services for wMammogram project."
        },
        {
            name: "Mr. Adam Lee Grady",
            about: "Graduate student of Computer Science at the University of South Dakota. He assisted Dr. Ahyoung Lee in the creation of the wMammogram web application."
        },
        {
            name: "Ms. Johanna Tietze",
            about: "Graduate student in social work at the University of South Dakota. She is a research assistant for this project. For this project she assisted Dr. Roh with document compilation and review."
        },
        {
            name: "Ms. Julieanne Morse",
            about: "Julieanne is a photographer. She serves as the project photographer and video maker. She ensures that our study materials have culturally appropriate visuals."
        },
        {
            name: "Funding",
            about: "This work was supported by Dakota Cancer Collaborative on Translational Activity (DaCCoTA) Scholars Award. The DaCCoTA is supported by the National Institute of General Medical Sciences of the National Institutes of Health under Award Number U54GM128729."
        }
    ]

    private w1084 = require("@/assets/hero-1084w.webp");

    private w1626 = require("@/assets/hero-1626w.webp");

    private w3252 = require("@/assets/hero-3252w.webp");
}
